import { NavLink, useMatch } from 'react-router-dom';
import { TiStarFullOutline } from "react-icons/ti";
import { GiHamburgerMenu } from "react-icons/gi";
import { SlArrowDown } from "react-icons/sl";
import { MdClose } from "react-icons/md";
import { useState } from 'react';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSubNavOpen, setIsSubNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    setIsSubNavOpen(false);
  }

  const toggleSubNavMenu = () => {
    setIsSubNavOpen(!isSubNavOpen);
  }

  return (
    <nav>
      <div className="desktop-navbar hidden sm:flex sm:flex-col justify-center items-center pb-1 sm:gap-2 lg:gap-4 xl:gap-6">
        <a href="/" className="">
          <img src="/assets/twelvevacancies_poster.png" alt="logo" className="sm:h-16 lg:h-20 xl:h-24 2xl:h-28 3xl:h-32 4xl:h-40" />
        </a>
        <div className={`navbar hidden justify-between px-2 py-1  bg-tvpink
                        sm:relative sm:flex sm:flex-row sm:items-center sm:border-y-2 sm:border-y-tvred
                        sm:w-full 2xl:border-y-4 4xl:border-y-8`}>
          <ul className='nav-items flex flex-col justify-between items-start gap-2 w-full font-alice font-bold text-white text-sm 
                        sm:flex-row sm:items-center sm:w-4/5 md:w-2/3 sm:text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl' onClick={toggleNav}>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit">
              <NavLink to="/" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>HOME</NavLink>
            </li>
            <li className="group relative w-full border-b border-tvpurple sm:border-0 sm:w-fit cursor-pointer">
              <div className={useMatch("/previous-editions/*") ? "text-tvred" : "text-white block"}>PREVIOUS EDITIONS</div>
              {/* Hover Bridge */}
              <div className="absolute left-0 w-full h-4 -mt-2 xl:-mt-0 group-hover:block"></div>
              {/* Dropdown */}
              <ul className="absolute left-0 top-full hidden group-hover:flex flex-col  bg-tvpink border 4xl:border-4 sm:border-t-2 2xl:border-2 2xl:border-t-4 4xl:border-t-8 border-tvred w-full sm:mt-2 md:mt-1.5 lg:mt-2 xl:mt-2.5 2xl:mt-2 3xl:mt-2.5 4xl:mt-4 z-10">
                <li className="sm:border-b 2xl:border-b-2 4xl:border-b-4 border-b-tvred">
                  <NavLink
                    to="/previous-editions/winter-2024"
                    className={({ isActive }) => isActive ? "text-tvred block px-4 py-2" : "text-white block px-4 py-2 hover:bg-tvred"}
                  >
                    WINTER 2024
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/previous-editions/fall-2024"
                    className={({ isActive }) => isActive ? "text-tvred block px-4 py-2" : "text-white block px-4 py-2 hover:bg-tvred"}
                  >
                    FALL 2024
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit">
              <NavLink to="/submit" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>SUBMIT</NavLink>
            </li>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit">
              <NavLink to="/about" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>OUR TEAM</NavLink>
            </li>
          </ul>
          <div className="flex flex-row justify-end sm:w-1/5 md:w-1/3">
            <TiStarFullOutline className="hidden text-white text-sm h-4  sm:block sm:h-6 sm:w-6 lg:h-8 lg:w-8 xl:h-10 xl:w-10 2xl:h-10 2xl:w-10 3xl:h-12 3xl:w-12 4xl:h-16 4xl:w-16"/>
          </div>
        </div>
      </div>
      <div className="mobile-navbar flex flex-row justify-center items-center border-b-2 border-tvred pb-1 relative sm:hidden">
        <div className="w-1/3">
        </div>
        <a href="/" className="flex flex-row items-cetner justify-center w-1/3">
          <img src="/assets/twelvevacancies_poster.png" alt="logo" className="h-10 w-auto max-w-full sm:hidden" />
        </a>
        <div className="flex flex-row justify-end items-center w-1/3 relative">
          <button onClick={toggleNav}>
            {isNavOpen ? <MdClose className="text-white h-9 w-9 " /> : <GiHamburgerMenu className="text-white h-8 w-8 "/>}
          </button>
          
        </div>
        <div className={`navbar-dropdown flex flex-col justify-between items-start border-0 shadow-xl border-tvred  px-2 py-1 w-2/3 bg-tvpink
                  top-full right-0 mt-1 ${isNavOpen ? 'flex absolute' : 'hidden'}`}>
            <ul className='nav-items flex flex-col justify-between items-start gap-2 w-full font-alice font-bold text-white text-sm' onClick={toggleNav}>
              <li className="w-full border-b border-tvpurple pb-1">
                <NavLink to="/" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>HOME</NavLink>
              </li>
              <li className="w-full border-b border-tvpurple pb-1 cursor-pointer">
                <div className="flex flex-row justify-between items-center" onClick={(e) => {
                  e.stopPropagation();
                  toggleSubNavMenu();
                }}>
                  <div className={useMatch("/previous-editions/*") ? "text-tvred" : "text-white block"}>PREVIOUS EDITIONS</div>
                  <SlArrowDown className="text-lg ml-4 flex-shrink-0"/>
                </div>
              </li>
              {isSubNavOpen ? 
                <ul className="flex flex-col w-full justify-between gap-2 font-alice font-bold text-sm items-start">
                  <li className="w-full border-b border-tvpurple pb-1 ml-0">
                    <NavLink
                      to="/previous-editions/winter-2024"
                      className={({ isActive }) => isActive ? "text-tvred block px-4" : "text-white block px-4"}
                    >
                      WINTER 2024
                    </NavLink>
                  </li>
                  <li className="w-full border-b border-tvpurple pb-1">
                    <NavLink
                      to="/previous-editions/fall-2024"
                      className={({ isActive }) => isActive ? "text-tvred block px-4" : "text-white block px-4"}
                    >
                      FALL 2024
                    </NavLink>
                  </li>
                </ul>
                : <></>}
              <li className="w-full border-b border-tvpurple pb-1">
                <NavLink to="/submit" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>SUBMIT</NavLink>
              </li>
              <li className="w-full border-b border-tvpurple pb-1">
                <NavLink to="/about" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>OUR TEAM</NavLink>
              </li>
            </ul>
          </div>
      </div>
    </nav>
  );
}

export default Navbar;  