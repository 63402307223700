import { useParams } from "react-router-dom"
import data from "./films.json"

type EditionData = {
  header: string,
  films: {
    title: string,
    director: string,
    description: string,
    credits: string,
    image: string
  }[]
}

const PreviousEdition = () => {
  const { edition } = useParams<string>(); 
  const editionData: EditionData = data[edition as keyof typeof data]

  return (
    <div className="flex flex-col gap-2 sm:gap-3">
      <h1 className="text-2xl font-bold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl lg:mb-3">{editionData.header}</h1>
      <div className="films flex flex-col gap-8 lg:gap-12">
        {editionData.films.map((film, index) => {
          return (
          <div className="film-card flex flex-row justify-between items-start sm:gap-5 md:gap-6 lg:gap-12 2xl:gap-24" key={index}>
            <div className="flex flex-col gap-2 sm:w-1/2">
              <div className="flex flex-col">
                <h3 className="font-sans font-bold text-base sm:text-sm md:text-base lg:text-lg xl:text-2xl 2xl:text-3xl">
                  <i>{film.title}</i> 
                </h3>
                <h4 className="font-bold text-sm sm:text-xs sm:max-w-full md:text-sm lg:text-base xl:text-lg 2xl:text-xl">
                  By {film.director}
                </h4>
              </div>
              <div className="w-full max-w-80 sm:hidden">
                <img src={`/assets/films/${edition}/${film.image}`} alt={`Still from ${film.title}`}/>
              </div>  
              <div className="film-desc flex flex-col max-w-80 text-sm gap-2 sm:text-xs sm:max-w-full md:text-sm lg:text-base xl:text-lg 2xl:text-xl">
                <div dangerouslySetInnerHTML={{ __html: film.description }}></div>
                <div dangerouslySetInnerHTML={{ __html: film.credits }}></div>
              </div>
            </div>
            <div className="hidden sm:block sm:w-1/2 sm:mt-1">
                <img src={`/assets/films/${edition}/${film.image}`} alt={`Still from ${film.title}`}/>
            </div>  
          </div>
          )
        })
      }
      </div>
    </div>
  );
}

export default PreviousEdition;