import { GoMail } from "react-icons/go";

const Footer = () => {
  return (
    <div className="footer font-alice flex flex-col w-full items-center gap-6 text-white text-xs sm:text-2xs md:text-xs lg:text-sm xl:text-base 2xl:text-lg">
      <div className="flex flex-row underline justify-between gap-10">
        <a href="https://filmfreeway.com/TwelveVacanciesFilmFestival" target="_blank" rel="noreferrer">filmfreeway</a>
        <a href="https://www.instagram.com/twelvevacancies/" target="_blank" rel="noreferrer">instagram</a>
      </div>
      <a href="mailto:twelvevacanciesfilmfest@gmail.com">
        <GoMail className="w-auto h-6 sm:h-2 md:h-4 lg:h-6 xl:h-8" />
      </a>
      <p className='text-2xs sm:text-3xs md:text-2xs lg:text-xs xl:text-sm 2xl:text-base'>
        &copy; 2025 Twelve Vacancies Film Festival. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
