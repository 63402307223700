const Home = () => {
  return (
    <div className="flex flex-col gap-5 sm:gap-8">
      <img src="/assets/FAN-FAVOURITE-2024.png" alt=""></img>
      <div className="about flex flex-col gap-4 sm:gap-3 lg:gap-6 xl:gap-8">
        <div className="flex flex-col w-full">
          <h1 className="font-bold text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl">
            TWELVE VACANCIES FILM FESTIVAL
          </h1>
          <h2 className="font-bold text-xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
            Winter 2025 Edition | March 20th & 21st at 7:00pm
          </h2>
        </div>
        <p className="text-sm sm:text-sm md:text-md lg:text-base xl:text-lg 2xl:text-xl">
          Twelve Vacancies Film Festival is a non-profit, student-run
          experimental and horror short film festival based at McGill University.
          Our mission is to create a platform for emerging youth filmmakers to
          share their work, and to strengthen the McGill and greater Montreal
          community through meaningful engagement with art. Twelve
          Vacancies Film Festival was founded in 2023 in collaboration with The
          Veg Magazine. 
          <br/><br/>
          TVFF is interested in screening visually innovative, engaging films. In
          the past, our jury has chosen both narrative genre films and expressive
          experimental films, with the goal of showcasing a range of mediums.
          <br/><br/>
          Our programming is decided by a student jury, who select twelve short
          films for our screening. Films are selected from filmmakers all over the
          world who are either under 25 years old or are students.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="font-bold text-2xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
          OUR SCREENINGS
        </h2>
        <div className="flex flex-col gap-4">
          <img src="/assets/tvffwebsitephotocollage.png" alt="Three photos from previous screenings of audiences watching films on a projector"/>
          <div className="flex flex-col gap-2">
            <p className="text-sm sm:text-sm md:text-md lg:text-base xl:text-lg 2xl:text-xl">
              Our short film selections are screened at 3475 Peel St, Montreal, QC, 
              Canada, with festivals occurring twice annually. The building is 
              located on the McGill downtown campus. To find us, walk north-west up 
              Peel St from Sherbrooke St and look for the building on the right side 
              of the street.
            </p>
            <p className="text-sm sm:text-sm md:text-md lg:text-base xl:text-lg 2xl:text-xl">
              The twelve short films at our screening are presented back to back, 
              and our events last approximately 1hr 30mins with a 10 minute 
              intermission in between. We serve free popcorn, sell stickers, and 
              have an audience choice award determined by a vote. Attending 
              filmmakers will receive free admission to the festival, free 
              festival merch, and in some cases can be connected to magazines 
              and newspapers for interviews.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="font-bold text-2xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
          WHAT PEOPLE ARE SAYING
        </h2>
        <div className="flex flex-col gap-2">
          <p className="text-sm sm:text-sm md:text-md lg:text-base xl:text-lg 2xl:text-xl">
            “What an awesome experience! The Twelve Vacancies team are a 
            passionate group who've been working hard for a few years now to make 
            a memorable night to celebrate experimental youth filmmaking. The 
            small setting is filled with love --- from free popcorn, beautiful 
            holographic event stickers, "4 favourite films" interviews, and more. 
            Overall, it's a really welcoming space and it was a blast to watch 
            the amazing curation of films this year, and an honour to have mine 
            screen as well.” - Nevin Louie, Director 
            of <i>Over the Hatch</i> and <i>Sixty Mixty!</i>
          </p>
          <p className="text-sm sm:text-sm md:text-md lg:text-base xl:text-lg 2xl:text-xl">
            “Thank you Twelve Vacancies for screening my film! The attending 
            experience was very nice and the selection of films was diverse and 
            interesting” - Sidonie Auclair-Gendron, 
            Director of <i>In My Sleep, I Swallow You</i>
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-5 w-full">
        <h2 className="font-bold text-2xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
          PRESS
        </h2>
        <div className="flex flex-col gap-4 text-sm md:text-md lg:text-base xl:text-lg  2xl:text-xl">
          <a href="https://www.forgetthebox.ca/arts/twelve-vacancies?rq=twelve%20vacancies" target="_blank" rel="noreferrer"
            className="flex flex-row justify-start items-center gap-4">
            <img src="/assets/forget-the-box.png" className="h-16" alt="Forget the box logo"/>
            <p className="underline">
              “Wacky Horror Picture Show: Twelve Vacancies Film Festivial” - Forget the Box
            </p>
          </a>
          <a href="https://www.thetribune.ca/a-e/twelve-vacancies-film-festival-plunges-into-the-uncanny/" 
            target="_blank" rel="noreferrer"
            className="flex flex-row justify-start items-center gap-4">
            <img src="assets/tribune.png" className="h-16" alt="The tribute logo"/>
            <p className="underline">
              “Twelve Vacancies Film Festival plunges into the uncanny” - The Tribune
            </p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;