const Submit = () => {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex flex-col items-center">
        <h1 className="text-2xl mb-1 font-bold sm:text-1xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
          CALL FOR SUBMISSIONS
        </h1>
        <h2 className="text-xl font-bold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
          Deadline: March 1st, 2025
        </h2>
      </div>
      <img src="/assets/submit.jpg" alt="" className="w-full sm:w-1/2"/>
      <div className="flex flex-col text-sm text-center mb-2 sm:text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl w-full sm:w-4/5 gap-3">
        <p>Twelve Vacancies Film Festival is seeking student or youth-made 
          films that fit under the themes of experimental, uncanny, or 
          horror and are under 10 minutes long. Films will be screened 
          on both March 20th and 21st at 3475 Rue Peel. 
        </p>
        <p>To submit, send an email 
          to <a href="mailto:twelvevacanciesfilmfest@gmail.com" className="underline break-words">twelvevacanciesfilmfest@gmail.com</a> containing 
          a google drive link to your film, or submit through our filmfreeway.
        </p>
      </div>
      <a href="https://filmfreeway.com/festivals/79300?utm_campaign=Twelve+Vacancies+Film+Festival&utm_medium=External&utm_source=Submission+Button" 
          target="_blank" rel="noreferrer" title="Click to submit on FilmFreeway" className="flex justify-start">
          <img src="https://public-assets.filmfreeway.com/submission_buttons/v2/med_submission_btn@2x-red.png" 
            title="Click to submit on FilmFreeway" alt="Film freeway logo" className="h-16 sm:h-24 2xl:h-36 3xl:h-48 w-auto"/>
      </a>
    </div>
    // <div className="flex flex-col items-center gap-4">
    //   <h1 className="text-2xl mb-1 font-bold sm:text-1xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">SUBMISSIONS ARE CLOSED</h1>
    //   <div className="flex flex-col text-sm text-center mb-6 sm:text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl w-3/5 gap-3">
    //     <p>Thanks to all who submitted to our Fall 2024 season! Check back in January to find out how to submit our next season.</p>
    //     <p>This year's films will be screened on November 22nd at 3475 Rue Peel.</p>
    //     <p>If you have any questions, don't hestitate to contact us 
    //       at <a href="mailto:twelvevacanciesfilmfest@gmail.com" className="underline" break-words
    // >twelvevacanciesfilmfest@gmail.com</a> </p>
    //   </div>
    //   <img src="/assets/submissions_closed.jpg" alt="" className="w-1/2"/>
    // </div>
  );
}

export default Submit;